<template>
  <div class="exchangeCon">
    <!-- 兑换类型列表 -->
    <div class="title">
      <h5 class="blod">
        {{ $i18n.locale == "en" ? $route.query.titleen : $route.query.title }}
      </h5>
      <p>{{ $i18n.locale == "en" ? $route.query.desen : $route.query.des }}</p>
    </div>
    <div class="typeBox">
      <!-- 礼券 -->
      <div class="typecon" v-if="type == 7">
        <div class="item" v-for="(item, i) in list" :key="i">
          <div class="coverimg">
            <q-img :src="item.picture" alt :ratio="30 / 33" />
          </div>
          <div class="txt">
            <div class="price">
              <span class="blod">{{
                $i18n.locale == "en" ? item.titleen : item.title
              }}</span>
            </div>
            <div class="desc blod">
              {{ formatIntegral(item.integral) }} {{ $t("vip.points_ex") }}
            </div>
            <div class="blackBtn">
              <q-btn
                dense
                type="submit"
                class="full-width"
                color="primary"
                :label="$t('vip.discover_more')"
                @click="golink(item)"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 免费配送-->
      <div class="typecon typecon2" v-else-if="type == 8">
        <div class="item" v-for="(item, i) in list" :key="i">
          <div class="coverimg">
            <q-img :src="item.picture" alt :ratio="30 / 33" />
          </div>
          <div class="txt">
            <div class="price">
              <span class="blod">{{
                $i18n.locale == "en" ? item.titleen : item.title
              }}</span>
            </div>
            <div class="desc blod">
              {{ formatIntegral(item.integral) }} {{ $t("vip.points_ex") }}
            </div>
            <div class="blackBtn">
              <q-btn
                dense
                type="submit"
                class="full-width"
                color="primary"
                :label="$t('vip.discover_more')"
                @click="golink(item)"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 贵宾卡 -->
      <div class="typecon typecon2" v-else-if="type == 9">
        <div class="item" v-for="(item, i) in list" :key="i">
          <div class="coverimg">
            <q-img :src="item.picture" alt :ratio="30 / 33" />
          </div>
          <div class="txt">
            <div class="name">
              {{ $i18n.locale == "en" ? item.titleen : item.title }}
            </div>
            <div class="precent">
              <span class="blod" style="visibility: hidden;"
                >{{ item.Price * 10 }}{{ $t("vip.discount") }}</span
              >
            </div>
            <div class="desc blod">
              {{ formatIntegral(item.integral) }} {{ $t("vip.points_ex") }}
            </div>
            <div class="blackBtn">
              <q-btn
                dense
                type="submit"
                class="full-width"
                color="primary"
                :label="$t('vip.discover_more')"
                @click="golink(item)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="typecon" v-else>
        <div class="item"></div>
      </div>
      <m-empty v-if="!list.length"></m-empty>
    </div>
  </div>
</template>

<script>
import mEmpty from '@/components/Empty.vue'
import { getPrize } from '@/api/my'
import { formatIntegral } from '@/utils/filterData.js'

export default {
  components: {
    mEmpty
  },
  data () {
    return {
      type: this.$route.query.type,
      list: []
    }
  },
  methods: {
    async getExchange () {
      const res = await getPrize({
        action: 'VIPdiscountlist',
        coupontype: this.type
      })
      this.list = res.VIPdiscount
    },
    golink (item) {
      this.$router.push('/my/exchangeDetail?info=' + JSON.stringify(item))
    }
  },
  mounted () {
    this.getExchange()
  },
  setup () {
    return {
      formatIntegral
    }
  }
}
</script>

<style lang="scss" scoped>
.exchangeCon {
  .title {
    text-align: center;
    h5 {
      font-size: 14px;
      margin-bottom: 5px;
      margin-top: 0;
    }
    p {
      font-size: 14px;
    }
  }
  .typecon {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px 0;
    &::after {
      content: "";
      width: 32%;
      border: 1px solid transparent;
    }
    .item {
      width: 32%;
      border: 1px solid #a8a8a8;
      border-radius: 5px;
      position: relative;
      overflow: hidden;
      margin-bottom: 30px;
      transition: all 0.3s;
      &:hover {
        box-shadow: 0 2px 12px #ccc;
        transform: translateY(-3px);
      }
      .coverimg {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
      .txt {
        width: 100%;
        color: #fff;
        position: absolute;
        left: 0;
        bottom: 0;
        text-align: center;
        .price {
          font-size: 27.5px;
          text-shadow: 1px 1px 2px #000;
        }
        .desc {
          font-size: 12px;
          text-shadow: 1px 1px 1px #000;
        }
        .name {
          font-size: 23px;
          padding: 0 5px;
          text-shadow: 1px 1px 2px #000;
        }
        .precent {
          font-size: 32px;
          margin-bottom: 8px;
          text-shadow: 1px 1px 2px #000;
        }
        .blackBtn {
          width: 90%;
          margin: 15px auto;
        }
      }
    }
  }
  .typecon2 {
    .item {
      border: none;
      .txt {
        color: #fff;
      }
    }
  }
}
</style>
